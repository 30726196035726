body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  background-image: url('../images/bg.gif');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #FFFFFF;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* #region General styles */
.d-row, .d-screen, .d-column {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.d-row, .d-screen {
  flex-direction: row;
}

.d-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.container,
.container-filled {
  width: 100%;
  max-width: 1100px;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.container-filled {
  max-width: none;
}

.container-inside {
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  justify-content: flex-start;
  position: relative;
}

.container-inside:not(.mobile-disabled) {
  margin: 160px auto 0 auto;
  height: calc(100vh - 160px);
}

.container-filled>.container-inside {
  margin: 10px auto;
  height: calc(100vh - 20px);
}

.w100 {
  width: 100% !important;
}

.link:hover {
  cursor: pointer;
  opacity: 0.7;
}

.linear-bg-wrapper {
  width: 100%;
  min-width: 150px;
  height: 20px;
  display: block;
  background-color: #ece4e4;
  position: relative;
  overflow: hidden;
  opacity: 0.5;
}

.linear-bg {
  background-color: #f80000;
  width: 100%;
  height: 100%;
  display: block;
  animation-name: bgLinear;
  animation-duration: 1s;
  animation-delay: -2s;
  /* animation-iteration-count: infinite; */
  animation-iteration-count: 60;
  position: absolute;
  top: 0;
  left: -10%
}

@keyframes bgLinear {
  0% {
    opacity: 1;
    left: 0
  }

  10% {
    opacity: 0.9;
    left: 10%;
  }

  20% {
    opacity: 0.8;
    left: 20%;
  }

  30% {
    opacity: 0.7;
    left: 30%;
  }

  40% {
    opacity: 0.6;
    left: 40;
  }

  50% {
    opacity: 0.5;
    left: 50%;
  }

  60% {
    opacity: 0.4;
    left: 60%;
  }

  70% {
    opacity: 0.3;
    left: 70%;
  }

  80% {
    opacity: 0.2;
    left: 80%;
  }

  90% {
    opacity: 0.1;
    left: 90%;
  }

  100% {
    opacity: 0;
    left: 100%;
  }
}

.fade-in {
  animation-name: opacity;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  /* animation-delay: 3s; */
}

@keyframes opacity {
  0% {
    opacity: 0
  }

  10% {
    opacity: 0.1
  }

  20% {
    opacity: 0.2
  }

  30% {
    opacity: 0.3
  }

  40% {
    opacity: 0.4
  }

  50% {
    opacity: 0.5
  }

  60% {
    opacity: 0.6
  }

  70% {
    opacity: 0.7
  }

  80% {
    opacity: 0.8
  }

  90% {
    opacity: 0.9
  }

  100% {
    opacity: 1
  }
}

/*  #endregion */

/* #region Header */
header {
  width: 100%;
  height: 140px;
  justify-content: space-between !important;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  border-bottom: 1px solid #E51B24;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(229, 27, 37, 1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(229, 27, 37, 1);
  box-shadow: 0px 3px 5px 0px rgba(229, 27, 37, 1);
  justify-content: space-evenly !important;
  align-items: flex-start !important;
}

header>.d-row {
  justify-content: space-between !important;
}

.logo-wrapper {
  width: max-content;
  max-width: 80%;
  height: 100%;
  padding-left: 10px;
}

.logotype {
  width: 60px;
  object-fit: contain;
  cursor: pointer;
}

.logo-holder {
  height: 70px;
  margin: auto 20px;
}

.logo-holder.d-row {
  justify-content: flex-start;
}

.logo-holder>p {
  margin: 0 !important;
}

.logo-holder>.linear-bg-wrapper {
  margin-top: 5px;
  height: 25px;
  width: 250px;
}

.logo-holder .select-list,
.logo-holder .select-list * {
  color: #FFFFFF !important;
  margin: 0 !important;
  height: max-content !important;
}

.logo-holder .select-list {
  font-size: 14px !important;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.774);
}

.logo-holder>p:first-child {
  font-size: 25px;
}


.position-view {
  width: calc(100% - 20px);
  font-size: 16px;
  margin: 0;
  padding: 0 10px;
  min-height: 30px;
  color: #FFFFFF !important;
  justify-content: space-between !important;
}

.position-view>p {
  width: max-content !important;
  margin: auto 0 !important;
  min-width: 200px;
}

.position-view svg {
  color: #E51B24;
}

.position-view>p:not(.connection-info)>span,
.position-view>p>span>span {
  font-weight: 600;
  color: #E51B24;
  margin: auto 10px auto 15px;
}

span.undefined {
  font-size: 10px;
  text-transform: uppercase;
  padding: 5px 7px;
  background-color: #cc0000c5;
  color: #FFFFFF !important;
  width: 200px !important;
  letter-spacing: 1px;
}

p.connection-info {
  font-size: 12px;
}

.alert-status {
  position: fixed;
  bottom: -150px;
  right: 50%;
  width: 95%;
  max-width: 320px;
  padding: 15px 0;
  border-radius: 5px;
  background-color: #E51B24;
  border: 2px solid transparent;
  transition: all 0.7s ease-in-out;
  transform: translate(50%, -50%);
  z-index: 3000;
}

.status-inactive {
  border-color: #E51B24;
  background-color: #000000;
}

.alert-status.status-visible {
  bottom: 5px;
}

.alert-status>p {
  font-size: 16px;
  height: max-content;
  margin: 0 !important;
  color: #FFFFFF;
}

.alert-status>span {
  width: 100%;
  text-align: center;
  height: 100%;
  font-size: 15px;
}

.status-inactive,
.status-inactive>p {
  color: #E51B24 !important;
}

.alert-status svg {
  margin: auto 10px;
}

.btn-container, .buttons-container {
  width: max-content;
  height: 100%;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: 10px;
}

.btn-container>button {
  width: 50px;
  height: 50px;
  border: 3px solid #E51B24;
  border-radius: 14px;
  margin: auto 0 auto 10px;
}

.active-link-btn {
  border-color: #FFFFFF !important;
}

.btn-container>button:hover,
.active-link-btn:hover,
.active-btn {
  border-color: #E51B24 !important;
  background-color: #E51B24 !important;
}

.btn-container>button>svg {
  font-size: 25px;
  color: #FFFFFF;
}

/* #endregion */

/* #region Start */
.start-wrapper {
  height: 100vh;
  flex-direction: column;
  position: relative;
}

.start-logotype {
  width: 150px;
  height: 150px;
  display: block;
  background-image: url(../images/adaptive.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.start-logo-text {
  font-size: 30px;
  font-family: monospace;
}

.login-button {
  width: 90%;
  max-width: 300px;
  height: 70px;
  display: block;
  border-color: #E51B24 !important;
  background-color: #E51B24 !important;
  color: #FFFFFF !important;
  font-weight: 700;
  margin-top: 10px !important;
}

.message-container {
  width: 400px;
  position: absolute;
  bottom: 15px;
}

.message-wrapper {
  margin-bottom: 0 !important;
}

/* #endregion */

/* #region Home */
button.incident-ctg, button.msg-text, .confirm-msg-buttons {
  width: 90%;
  max-width: 320px;
  margin: 5px auto !important;
  letter-spacing: 1px;
  position: relative;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-box-shadow: 0 0 3px 1px rgba(170, 11, 11, 0.5) !important;
  -moz-box-shadow: 0 0 3px 1px rgba(170, 11, 11, 0.5) !important;
  box-shadow: 0 0 3px 1px rgba(170, 11, 11, 0.5) !important;
}

button.incident-ctg {
  height: 110px;
}

button.incident-ctg, button.msg-text {
  display: block;
}

button.incident-ctg {
  background-color: #E51B24 !important;
  color: #FFFFFF !important;
  font-size: 18px !important;
}

button.incident-ctg.internal {
  background-color: #cc0000a1 !important;
  font-size: 14px !important;
}

.incident-ctg:first-child {
  margin: 40px auto !important;
}

button.msg-text, .confirm-msg-buttons {
  background-color: #ca000a;
  height: auto !important;
  padding: 40px 10px;
  max-width: 500px !important;
  transition: all 0.5s ease-in-out;
}

.tooltip-default,
.tooltip-red,
.tooltip-warning,
.tooltip-internal {
  padding: 10px 12px !important;
  font-size: 16px !important;
  text-align: center !important;
  cursor: alias !important;
  letter-spacing: 0.5px !important;
}

.tooltip-red, 
.tooltip-warning {
  background-color: #cc0000 !important;
}

.tooltip-internal {
  background-color: rgba(238, 112, 9, 0.85) !important;
}

.tooltip-arrow-red, 
.tooltip-arrow-warning {
  color: #cc0000 !important;
}

.tooltip-arrow-internal {
  color: rgba(238, 112, 9, 0.85) !important;
}

.test-mode-emails {
  width: 90%;
  margin: auto !important;
}

.test-mode-emails>*,
.test-mode-emails fieldset {
  color: #FFFFFF !important;
  border-color: #cc0000 !important;
}

.test-mode-emails p.MuiFormHelperText-contained {
  color: #FFFFFF !important;
  font-size: 11px !important;
  text-transform: unset !important;
  font-style: italic;
}

.confirm-msg-buttons {
  background-color: #000000 !important;
  border: 1px solid #cc0000;
  width: calc(100% - 24px) !important;
  padding: 0 10px !important;
  justify-content: center !important;
  min-height: 136px !important;
  margin: 0 auto !important;
}

.confirm-msg-buttons div>button {
  margin: 0 5px !important;
}

.confirm-msg-buttons>.loading-box {
  position: unset !important;
}

/* #endregion */

/* #region Side menu */
.side-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100Vh;
  background: rgba(255, 255, 255, 0.1);
  display: block;
  transition: all 0.6s ease-in-out;
  z-index: 5000;
}

.side-menu {
  position: absolute;
  top: 0;
  right: -101%;
  width: 400px;
  max-width: 100%;
  height: 100%;
  background: #000000;
  border-left: 5px solid #b41414;
  transition: all 0.9s ease-in-out;
  overflow-y: auto;
}

.side-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(238, 9, 9, 0.6);
  border-radius: 7px;
}

.side-menu::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(238, 7, 7, 0.9);
}

.side-menu-visible {
  right: 0;
}

.side-menu>.logo-wrapper {
  width: calc(100% - 40px) !important;
  max-width: unset !important;
  margin: 0;
  background-color: #b41414;
  color: #FFFFFF;
  padding: 27px 20px;
  justify-content: space-between;
  height: max-content !important;
  position: sticky;
  top: 0;
  z-index: 3000;
}

.sm-wrapper {
  width: 100%;
  min-height: calc(100% - 150px);
  justify-content: space-between;
}

.sm-links-wrapper {
  width: 100%;
  height: max-content;
}

.sm-link {
  width: 90% !important;
  margin: 10px auto !important;
  border-bottom: 2px solid #cc0000 !important;
  text-transform: uppercase;
  font-size: 18px !important;
  color: #FFFFFF !important;
  cursor: pointer;
  transition: all 0.7s ease-in-out !important;
  align-items: flex-start !important;
  padding: 0 5px;
  color: #FFFFFF;
  opacity: 0.8;
  min-height: 70px;
  max-height: 70px;
  overflow: hidden;
}

.sm-link>p {
  justify-content: space-between;
  margin: auto 0 !important;
}

.sm-link.dropdown {
  max-height: 1000px;
}

.sm-link:not(.dropdown):hover,
.sm-link.dropdown {
  letter-spacing: 2px !important;
  opacity: 1;
}

.sm-link>p>span>svg {
  margin-right: 15px;
}

.sm-link.active>svg {
  color: #cc0000;
  font-size: 30px;
}

.sm-link:hover>svg {
  color: #cc0000;
  font-size: 40px;
}

p.sm-sub-links-wrapper {
  width: -webkit-fill-available;
  margin: 0 0 10px 40px !important;
  outline: unset !important;
}

.sm-sub-link {
  width: 100%;
  font-size: 12px;
  padding: 7px;
  letter-spacing: 2px;
  transition: all 0.4s ease-in-out;
  letter-spacing: 2px !important;
}

.sm-sub-link:hover {
  color: #ee0707;
}

.sm-sub-link:first-of-type {
  padding-top: 0;
}

.sm-link>p>span,
.sm-sub-link {
  justify-content: flex-start;
}

.sm-wrapper>button {
  width: 90%;
  height: 60px;
  margin: 30px auto;
}

/* #endregion */

/* #region Messages */
.box-wrapper {
  width: 900px;
  max-width: 95%;
  height: calc(100% - 30px);
  padding: 10px;
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
}

.box-wrapper .form-wrapper {
  margin: 0 auto;
}

.box-wrapper>.d-column {
  height: calc(100% - 170px);
  overflow-y: auto;
  justify-content: flex-start;
}

.box-heading {
  font-size: 25px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #E51B24;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif, Courier, monospace;
  width: calc(100% - 20px);
  margin: 0 auto 30px auto;
  font-weight: 600;
  justify-content: space-between;
}

.box-warning {
  border-color: rgba(229, 27, 37, 0.8) !important;
  -webkit-box-shadow: 0px 1.8px 6px 0px rgba(229, 27, 1.87, 0.8) !important;
  -moz-box-shadow: 0px 1.8px 6px 0px rgba(229, 27, 1.87, 0.8) !important;
  box-shadow: 0px 1.8px 6px 0px rgba(229, 27, 1.87, 0.8) !important;
}

.box-internal {
  border-color: rgba(238, 112, 9, 0.85) !important;
  -webkit-box-shadow: 0px 1.8px 6px 0px rgba(238, 112, 9, 0.85) !important;
  -moz-box-shadow: 0px 1.8px 6px 0px rgba(238, 112, 9, 0.85) !important;
  box-shadow: 0px 1.8px 6px 0px rgba(238, 112, 9, 0.85) !important;
}

.box-solved {
  border-color: #BBE2C6 !important;
  -webkit-box-shadow: 0px 1.8px 6px 0px #BBE2C6 !important;
  -moz-box-shadow: 0px 1.8px 6px 0px #BBE2C6 !important;
  box-shadow: 0px 1.8px 6px 0px #BBE2C6 !important;
}

.box-heading svg {
  font-size: 30px !important;
}

.box-warning>.box-header-wrapper svg,
.box-warning>.box-header-wrapper h3,
.color-warning {
  color: rgba(229, 27, 37, 0.8) !important
}

.unread-warning {
  border-style: solid !important;
  border-color: #cc0000 !important;
  background-color: #cc0000 !important;
}

.box-internal>.box-header-wrapper h3,
.box-internal>.box-header-wrapper svg,
.color-internal {
  color: rgba(238, 112, 9, 0.85) !important;
}

.unread-internal {
  border-style: solid !important;
  border-color:  #CB5F08 !important;
  border-bottom-width: 4px !important;
}

.box-solved>.box-header-wrapper h3,
.box-solved>.box-header-wrapper svg,
.color-solved {
  color: #FFFFFF !important
}

.data-container {
  margin: 15px auto 20px auto;
  justify-content: flex-end !important;
}

.data-container .select-list {
  color: #FFFFFF !important;
}

.data-container>div:not(.test-mode-emails) {
  width: 200px !important;
}

.data-container>div>label,
.data-container>div svg {
  color: #FFFFFF !important;
}

.data-container .select-list fieldset {
  border-color: #FFFFFF !important;
}

.msg-text {
  padding: 40px 15px !important;
  font-size: 18px !important;
  text-transform: none !important; 
  background-color: transparent !important;
}

.msg-text.internal {
  border: 2px solid rgba(238, 112, 9, 0.85) !important;
}

.msg-text.warning {
  border: 2px solid  #cc0000 !important;
}

.msg-text:hover {
  opacity: 0.6 !important;
}

.msg-text>span:last-child {
  display: none !important;
}

.select-list .Mui-disabled {
  -webkit-text-fill-color: rgba(143, 142, 142, 0.575) !important;
}

p.menu-item {
  justify-content: space-between;
  margin: 2px 0;
  text-indent: 7px;
}

.menu-item>span:first-child {
  width: 85%;
  display: flex;
}

.menu-item>span:first-child>span:last-child {
  margin-left: 10px;
  text-indent: 0;
}

span.count-active,
span.count-inactive {
  display: block;
  font-size: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  border-radius: 50%;
  width: 20px;
  padding: 3px 0;
  text-align: center;
  text-indent: 0;
  border: 0.5px solid transparent
}

span.count-active {
  background-color: #cc0000 !important;
  color: #FFFFFF !important;
  border-color: #cc0000;
}

span.count-inactive {
  border-color: #272323 !important;
  color: #272323 !important;
}

.box-btn-container button {
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  padding: 8px;
  color: #FFFFFF;
  background-color: transparent;
}

.box-btn-container button,
.box-btn-container>span>button {
  margin-left: 10px;
}

.box-btn-container button:hover {
  background-color: #cc0000;
}

.box-btn-container button svg {
  font-size: 25px !important;
}

.alert-wrapper {
  width: calc(100% - 60px);
  margin: -10px auto 0 auto;
}

.alert-wrapper>div:not(.MuiAlert-icon) {
  width: 100%;
}

.alert-buttons {
  width: 100%;
  text-align: right;
}

.alert-buttons>button:last-child {
  margin-left: 10px;
}

/* #endregion */

/* #region Warnings popup */
.warnings-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 3000;
  background-color: #520101d7;
}

.warning-wrapper {
  width: 400px;
  max-width: calc(100% - 40px);
  background: #080808c4;
  padding: 20px 10px;
  -webkit-box-shadow: 0px 1px 12px 0px #FFFFFF;
  -moz-box-shadow: 0px 1px 12px 0px #FFFFFF;
  box-shadow: 0px 1px 12px 0px #FFFFFF;
}

.warning-wrapper svg {
  font-size: 90px;
  color: #cc0000;
  cursor: pointer;
}

.warning-wrapper p {
  width: 100%;
  text-align: center;
}

p.warning-name {
  font-size: 30px !important;
  color: #cc0000 !important;
  margin: 0 !important;
  font-family: Impact !important;
  text-transform: uppercase !important;
}

p.warning-place {
  margin-top: 5px !important;
  font-size: 14px !important;
}

p.warning-text {
  font-size: 16px !important;
  font-family: Arial, Helvetica, sans-serif !important;
  letter-spacing: 1px !important;
  margin: 20px 0 0 0 !important;
  width: 90% !important;
  padding: 15px !important;
  border-radius: 3px;
  background-color: rgba(204, 0, 0, 0.8) !important;
}

/* #endregion */

/* #region Current messages  */
.notification-view {
  width: 96%;
  margin: auto !important;
  height: -webkit-fill-available !important;
}

.notification-view>.message-box {
  margin-bottom: 0 !important;
  width: -webkit-fill-available !important;
}

.notification-view>div {
  width: 100% !important;
  position: relative !important;
  margin: 10px auto !important;
}

.label-block {
  align-self: flex-end;
  margin: 0 0 10px 0 !important;
  width: max-content !important;
}

.label-block>span {
  font-size: 15px;
  margin-left: 14px;
  padding: 3px 0;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.notification-view div.message-content {
  margin: 10px auto !important;
  margin-bottom: 25px !important;
  font-family: 'Courier New', Courier, monospace;
  justify-content: flex-start !important;
  width: -webkit-fill-available;
}

.notification-view .message-content>p:not(.alert-sub-text) {
  margin: auto 0 !important;
  align-self: flex-start !important;
}


p.alert-sub-text {
  width: -webkit-fill-available !important;
  margin-top: 20px !important;
  padding: 10px 10px;
  font-size: 14px !important;
  font-style: italic;
  background-color: #bbe2c642 !important;
  color: #BBE2C6 !important;
  align-items: center !important;
  justify-content: flex-start;
}

p.alert-sub-text svg {
  color: #BBE2C6 !important;
  margin: auto 10px auto 0;
}

.map-container {
  height: 500px;
  max-height: 40vh;
}

.map-wrapper {
  height: 100%;
  width: 100%;
  margin: auto;
}

.map-wrapper>div:first-of-type {
  position: unset !important;
}

.message-box {
  background: transparent !important;
  margin-bottom: 30px !important;
  color: #FFFFFF !important;
  transition: all 0.8s ease-in-out;
  overflow: visible !important;
  height: max-content !important;
}

.message-box>div {
  margin-bottom: 0 !important;
  padding: 5px !important;
}

p.message-data {
  margin: 20px 5px 10px 5px !important;
  justify-content: space-between;
}

.message-data>span.d-column {
  align-items: flex-start;
}

.message-data>span>span {
  width: max-content;
  font-size: 13px;
  margin: 3px 0 0 0;
  font-family: 'Courier New', Courier, monospace;
}

.message-data>span svg {
  font-size: 18px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.message-box p {
  color: #FFFFFF !important;
}

.message-actions-wrapper {
  color: #FFFFFF !important;
  height: auto !important;
  width: calc(100% - 20px);
  padding: 10px !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
}

/*  #endregion */

/* #region Notifications */
.notifications-container {
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  right: -275px;
  width: max-content;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  transition: all 0.9s ease-in-out;
  align-items: end !important;
  justify-content: flex-end !important;
}

.visible-notifications {
  right: 0;
  z-index: 3000;
}

.notifications-container>.d-column {
  justify-content: flex-end;
  background-color: #020202b4 !important;
  height: 100vh;
  width: auto;
  padding: 10px 0 15px 10px;
}

.notification-wrapper {
  height: auto;
  max-width: "100%";
  width: 250px;
  overflow-y: auto;
  padding-right: 15px;
}

.notification-block {
  background-color: #000000ce;
  padding: 8px;
  width: calc(100% - 20px);
  border: 2px solid #cc0000;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  overflow: hidden;
}

.notification-block.warning-unread {
  background-color: #cc0000 !important;
  color: #FFFFFF !important;
}

.notification-block.internal-unread {
  background-color: rgba(238, 112, 9, 0.85) !important;
  color: #FFFFFF !important;
}

.notification-block.internal-unread,
.notification-block.internal {
  border-color: rgba(238, 112, 9, 0.85) !important;
}


.notification-block.internal-unread:hover,
.notification-block.internal:hover {
  background-color: rgba(238, 112, 9, 0.45) !important;
}

.notification-block.solved {
  border-color: #FFFFFF;
}

.notification-block:not(.solved):not(.internal):not(.internal-unread):hover {
  background-color: #cc0000a6 !important;
  border-color: #cc0000a6 !important;
}

.notification-block:hover>*,
.notification-block.solved>*:not(.notification-sub-text) {
  color: #FFFFFF !important;
}

.notification-block>h4 {
  margin: 0 0 10px 0;
  justify-content: space-between;
  padding-bottom: 5px;
}

.notification-block:not(.solved):not(.warning-unread):not(.internal-unread):not(.internal)>h4 {
  color: #cc0000;
}

.notification-block.internal>h4 {
  color: rgba(238, 112, 9, 0.85) !important;
}

.notification-block>h4>span {
  width: 100%;
}

.notification-block>h4>svg {
  font-size: 20px;
  margin-left: 3px;
}

.notification-block>p {
  margin: 0;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-indent: 7px;
}

.notification-block>p:last-child {
  text-indent: 0;
  width: max-content;
  padding: 3px 4px;
  margin: 5px 0 0 auto;
}

.notification-block.warning>p:last-child {
  background-color: #cc0000 !important;
}

.notification-block.internal>p:last-child {
  background-color: rgba(238, 112, 9, 0.85);
}

.notification-btn {
  width: 45px;
  height: 45px;
  padding: 0 !important;
  color: #FFFFFF !important;
  z-index: 2000;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  background-color: #b41414 !important;
}

.notification-btn svg {
  color: #FFFFFF !important;
  font-size: 25px !important;
}

.notification-wrapper>div:first-child:not(div:last-child) {
  margin-bottom: 15px !important;
}

.notification-wrapper>div>h4 {
  margin: 3px 0 5px 0 !important;
  font-family: monospace;
  text-transform: uppercase;
  letter-spacing: 1px;
  justify-content: flex-start;
}


.notification-wrapper>div>h4>svg {
  margin-right: 10px;
  font-size: 15px !important;
}

/* #endregion */

/* #region Popup */
.popup-container {
  height: 100vh;
  z-index: 3000;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000000c2;
}

.popup-content,
.popup-content.solved,
.popup-content.internal,
.popup-content.warning {
  max-width: 90%;
  background: #FFFFFF;
  border-radius: 3px;
  padding: 5px;
  width: 400px;
}

.popup-content.warning {
  border: 1px solid #858181;
  -webkit-box-shadow: 0px 1px 12px 0px #858181;
  -moz-box-shadow: 0px 1px 12px 0px #858181;
  box-shadow: 0px 1px 12px 0px #858181;
}

.popup-content.solved {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  max-width: 90%;
  margin-bottom: 20px;
  border-radius: 0;
  cursor: pointer;
  padding: 15px;
}

.popup-content.solved>div {
  margin-left: 30px;
  width: 90%;
  border: 1px solid #65B102;
  padding: 15px;
  border-radius: 3px;
}

.popup-content.internal {
  border: 1px solid rgba(238, 112, 9, 0.85);
  -webkit-box-shadow: 0px 1px 12px 0px rgba(238, 112, 9, 0.85);
  -moz-box-shadow: 0px 1px 12px 0px rgba(238, 112, 9, 0.85);
  box-shadow: 0px 1px 12px 0px rgba(238, 112, 9, 0.85);
  cursor: pointer;
}

.popup-content svg {
  font-size: 100px;
  margin: 10px auto;
  display: block;
}

.popup-content.warning svg {
  color: #858181;
}

.popup-content.solved svg {
  color: #65b102;
  margin-left: 20px;
}

.popup-content.internal svg {
  color: rgba(238, 112, 9, 0.85);;
}

.popup-content>div {
  width: 95%;
  margin: 0 auto;
}

.popup-content>div>*:not(.updated-message) {
  color: #000000 !important;
  font-size: 14px !important;
  text-align: center;
  margin: 5px auto;
}


.popup-content.solved>div>*:not(.updated-message),
.popup-content.internal>div>*:not(.updated-message) {
  color: #000000 !important;
  font-size: 20px !important;
  text-align: center;
  margin: 5px auto;
}

.popup-content.solved>div>p.updated-message,
.popup-content.internal>div>p.updated-message {
  padding: 18px;
  text-align: center;
  margin: 30px auto 10px auto;
}

.popup-content.solved>div>p.updated-message {
  padding: 14px;
}

.popup-content.solved>div>p.updated-message {
  color: #535353;
}

.popup-content.internal>div>p.updated-message {
  color: #FFFFFF;
  background-color: rgba(238, 112, 9, 0.85) !important;
}

/* #endregion */

/* #region Connection list */
.list-li-wrapper,
.statistics-list-wrapper,
.notifications-list-wrapper {
  width: auto;
  max-width: 600px;
  color: #ccFFFF;
  margin: 20px auto !important;
}

.container-filled .list-li-wrapper {
  width: 800px;
  max-width: 90%;
}

.list-li-wrapper>li,
.statistics-list-wrapper>li .notifications-list-wrapper>li {
  width: 100%;
}

.list-li-wrapper>li>div>span {
  font-size: 18px !important;
}

.list-li-wrapper>li>div>p {
  font-size: 14px !important;
  color: #dbdbdb !important;
}

.list-li-wrapper>li:not(.list-li-names) {
  margin-top: 10px !important;
  background-color: #cc0000 !important;
}

.list-li-block>div {
  width: 100% !important;
  background-color: #000000 !important;
}

.statistics-list-wrapper>li,
.notifications-list-wrapper>li {
  border: 1px dashed #E51B24;
  margin-bottom: 10px !important;
}

.notifications-list-wrapper>li.warning {
  border-color: rgba(229, 27, 37, 0.8);
}

.notifications-list-wrapper>li.internal {
  border-color: rgba(238, 112, 9, 0.85);
}

.notifications-list-wrapper>li.solved {
  border-color: #FFFFFF;
}

li.list-panel {
  width: 100% !important;
  justify-content: space-between !important;
  font-family: 'Courier New', Courier, monospace;
  font-size: 18px;
  border-color: transparent !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
}

li.list-li-row {
  margin: 20px 0;
}

li.list-li-names,
.list-li-wrapper>li:last-child {
  border: 1px dashed #E51B24 !important;
  background-color: transparent !important;
}

li.list-li-names {
  border-top: 0 !important;
  margin-bottom: 20px !important;
  border-top: 0 !important;
}

li.list-li-names>button {
  align-self: flex-start;
  width: max-content;
  margin-top: 10px;
}

.list-div-buttons {
  justify-content: flex-start;
  margin: 10px 0;
}

.list-div-buttons>button {
  width: max-content;
}

li.list-li-names>div {
  flex: none !important;
  width: 100% !important;
  display: block !important;
}

li.list-li-names>div>span {
  margin: 5px 0 !important;
  font-size: 14px ! important;
  color: #FFFFFF !important;
  letter-spacing: 1px;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  width: 100% !important;
}

li.list-li-names>div>span>span.d-row {
  width: max-content !important;
}

li.list-li-names>div>span>span.d-row>svg {
  margin-right: 10px !important;
}

.avatar {
  background: transparent !important;
  border: 2px solid #FFFFFF;
  margin-right: 20px;
  color: #FFFFFF
}

.statistics-list-wrapper .avatar,
.notifications-list-wrapper .avatar {
  border-color: #cc0000;
}

.notifications-list-wrapper>li.warning:not(.unread-warning) .avatar {
  border-color: rgba(229, 27, 37, 0.8) !important;
}

.notifications-list-wrapper>li.internal .avatar {
  border-color: rgba(238, 112, 9, 0.85) !important;
}

.notifications-list-wrapper>li.solved .avatar {
  border-color: transparent !important;
}

.notifications-list-wrapper>li.unread-internal .avatar {
  background-color: #CB5F08 !important;
}

.notifications-list-wrapper>li:not(.list-panel) span {
  text-overflow: ellipsis !important;
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  font-size: 14px;
}

.notifications-list-wrapper>li p {
  margin: 3px 0 0 0 !important;
  color: #FFFFFF !important;
  font-size: 10px !important;
}

/* #endregion */

/* #region Settings */
.box {
  background-color: #FFFFFF;
  color: #000;
  min-height: calc(100% - 15px);
  overflow-y: auto;
  max-width: 95%;
  padding: 0 10px;
  position: relative;
  width: 900px;
  margin: auto auto 10px auto;
  justify-content: flex-start;
}

.container-filled .box {
  margin-bottom: 0;
  min-height: 100%;
  width: 95%;
}

.box-header-wrapper>h3 {
  margin: 0;
  width: max-content;
}

.box-header-wrapper>h3>svg {
  margin-right: 15px !important;
}

.box-header-wrapper .linear-bg-wrapper {
  height: 25px !important;
}

.box-header-wrapper>p {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  letter-spacing: 1px;
  width: max-content !important;
  align-self: flex-start;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #FFFFFF;
}

.box-header-wrapper>p>svg {
  margin-right: 10px;
  font-size: 24px !important;
}

.box-menu>.box-header-wrapper>p {
  color: #9b9b9b !important;
  font-size: 16px !important;
}

.box-menu {
  height: 50px;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 0 auto;
  border-bottom: 2px solid #E51B24;
  justify-content: space-between;
  position: relative;
}

.box-menu>p {
  color: #000000;
  font-size: 20px;
  margin: auto 0 !important;
  width: max-content !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.buttons-container {
  margin: auto 0;
}

.buttons-container>button {
  margin-left: 5px;
  margin-right: 0 !important;
}

.search-wrapper {
  position: relative;
  width: 300px;
  height: 50px;
  margin-top: 0 !important;
}

.search-input,
.search-input>* {
  width: 100% !important;
  max-width: none !important;
  height: 50px;
  border-radius: 5px;
}

.search-wrapper fieldset,
.search-wrapper div.Mui-focused fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.search-wrapper button {
  position: absolute;
  right: 3px;
  top: 4px;
}

.alert-wrapper {
  width: calc(100% - 34px);
  margin: 10px auto 5px auto;
  overflow: hidden;
}

.alert-wrapper>div:last-child {
  width: 100%;
}

.alert-wrapper div:last-child div:not(div:first-child) {
  margin: 15px auto 0 auto;
  width: 98%;
}

.alert-wrapper p:not(p:last-child) {
  margin: 3px 0 !important;
}

.alert-wrapper a {
  width: max-content;
  float: right;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
  text-decoration: none !important;
  margin-top: 15px;
  color: #353535;
}

.alert-wrapper a:hover {
  opacity: 0.6;
}

.alert-wrapper a svg {
  margin-right: 10px;
  font-size: 20px;
}

.pagination {
  display: flex;
  margin: 20px 0;
  width: max-content;
  align-items: flex-end;
}

.btn-count {
  font-size: 14px;
  padding: 3px !important;
  display: inline-block !important;
  margin: auto 15px auto 5px !important;
  cursor: help;
}

.list-wrapper {
  margin-top: 3px;
  width: 100%;
  z-index: 1000;
}

.menu-div {
  transition: all 0.6s ease-in-out;
  opacity: 1;
}

.list-wrapper.hidden,
.menu-div.hidden,
.table-row.hidden {
  opacity: 0.2 !important;
  pointer-events: none !important;
}

p.help-information,
.MuiFormHelperText-contained {
  font-size: 11px !important;
  letter-spacing: 1px !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
  text-transform: uppercase !important;
  margin-top: 10px !important;
}

p.help-information {
  margin-top: -10px;
  color: rgb(60, 60, 60);
}

.MuiFormHelperText-contained:hover {
  color: #3030df !important;
  cursor: pointer !important;
}

.form-fields,
.for-textarea {
  margin: 5px 0 15px 0 !important;
}

form input,
form>div>input,
form textarea,
form>div>textarea {
  font-size: 16px !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  color: #000000bd !important;
}

.form-textarea-wrapper {
  max-width: calc(100% - 29px);
  position: relative;
  color: #000000bd !important;
  margin-top: 7px;
}

.form-textarea-label {
  font-size: 12px !important;
  position: absolute !important;
  left: 12px !important;
  top: -10px !important;
  padding: 0 8px 0 5px !important;
  background-color: #FFFFFF !important;
  width: max-content !important;
  font-weight: 200 !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.form-textarea {
  width: 100%;
  max-height: 200px;
  border-radius: 3px;
  border: 0;
  box-sizing: content-box;
  background: none;
  margin: 5px 0 15px 0;
  display: block;
  min-width: 0;
  color: inherit !important;
  padding: 16.5px 14px;
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

textarea::placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 16px !important;
}

.row-label {
  font-size: 14px;
  justify-content: space-between;
  margin: 10px 0;
}

.form-fields .MuiFormHelperText-contained {
  font-size: 10px !important;
  text-transform: none !important;
  margin-top: 3px !important;
  font-style: italic !important;
}

.form-fields .MuiFormHelperText-contained:hover {
  color: inherit !important;
}

.modal-field {
  width: 100% !important;
  border-color: #FFFFFF !important;
  background-color: #FFFFFF;
  margin-bottom: 25px !important;
}

.modal-counter {
  margin-bottom: 15px !important;
  font-weight: 500 !important;
}

.modal-counter>span {
  color: #cc0000 !important;
}

.modal {
  position: absolute;
  top: 80px;
  padding: 25px 30px;
  background-color: #FFF;
  z-index: 3000;
  box-shadow: 0 0 3px 3px #ccc;
  border-radius: 3px;
  transition: all 0.9s ease-in-out;
  width: 350px;
  border: 1px solid #ccc;
}

.modal>h3 {
  margin-top: 10px !important;
}

.modal>p {
  justify-content: flex-start;
  font-size: 15px;
  width: 95%;
  margin: 5px auto 10px auto;
}

.modal>p>svg {
  margin-right: 10px;
}

.modal>svg {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal>svg:hover {
  opacity: 0.5;
}

.table-row {
  width: 98%;
  height: 50px;
  margin: auto;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(189, 189, 189);
}

.table-row:hover {
  background-color: #c3c1c11e;
}

.table-row>p:not(.list-item):not(.error-response) {
  min-width: 50px;
  text-align: center;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.table-row>.form-btn-container {
  margin: auto 0 !important;
}

.list-item {
  width: inherit;
  text-align: left;
  color: #373737;
  justify-content: flex-start;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.disabled-block,
.disabled-block span {
  z-index: -1 !important;
  opacity: 0.7 !important;
  pointer-events: none !important;
  color: #7a7979 !important;
  font-size: 11px !important;
}

.error-response {
  width: 100% !important;
  font-weight: 600;
  letter-spacing: 1px;
  color: #cc0000;
  justify-content: flex-end;
  padding-right: 10px;
}

.error-response svg {
  margin-right: 10px;
}

.list-item>span:last-child:hover {
  color: #1c2cbe;
  cursor: pointer;
}

.list-item>span>span:not(span:first-child) {
  display: block;
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;
  font-family: monospace;
  color: #cc0000;
}

.no-link {
  pointer-events: none !important;
}

.table-row-buttons {
  width: max-content !important;
}

.table-row-buttons button {
  width: 40px;
  height: 40px;
}

.menu-div {
  justify-content: space-between;
  margin: 15px auto 10px auto;
  position: relative;
  color: #000000 !important;
  height: 55px;
}

.menu-div ul {
  left: 0 !important;
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 12px !important;
}

/* #endregion */

/* #region Form */
.form-wrapper {
  width: 500px;
  max-width: 95%;
  border: 1px solid #c4c4c4;
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 3px;
  padding: 10px 20px;
  position: relative;
}

.form-label {
  width: -webkit-fill-available !important;
  justify-content: space-between !important;
  margin-bottom: 20px;
}

.response-wrapper {
  background-color: transparent;
  padding: 0;
}

.form-wrapper:not(.box-wrapper .form-wrapper) {
  margin: 30px auto;
}

.form-wrapper>.loading-box,
form.user-data>.loading-box {
  height: 100%;
  width: 100%;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
}

.form-wrapper .select-wrapper {
  margin-bottom: 25px !important;
}

ul[role="listbox"] {
  max-height: 300px !important;
}

.disabled-message {
  font-size: 14px;
  color: #cc0000;
  align-self: flex-end;
  width: 200px;
  border: 2px solid transparent;
}

.disabled-message>svg {
  margin-right: 15px;
  font-size: 14px;
  color: #cc0000;
}

.notification-content {
  align-items: flex-start;
  opacity: 0.7;
  padding: 10px 3px;
  margin: -10px 0 5px 0;
  width: auto;
}

.notification-content>h4 {
  margin: 0 0 5px 0;
  color: #cc0000;
}

.notification-content>p {
  margin: 0;
  font-style: italic;
  font-size: 12px;
  align-items: flex-start;
}

.response-box {
  color: #000000 !important;
  height: max-content;
  width: 100% !important;
  margin: 30px 0 0 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.response-box>div:not(.response-buttons) {
  width: 500px;
  justify-content: flex-start;
  max-width: 100%;
}

.response-box>div>* {
  margin: auto 0;
  font-weight: 600;
}

.response-box .response-message {
  width: 100%;
  margin: auto;
}

.response-box .error-message {
  margin: 0 auto;
  font-size: 11px;
  font-style: italic;
  color: #FFFFFF;
  width: auto !important;
  display: block;
  padding: 12px;
  max-width: 100%;
  margin-top: 15px;
  border-radius: 3px;
  background-color: rgba(204, 0, 00, 0.2);
}

.d-column.response-wrapper {
  min-height: 136px;
}

.d-column.response-wrapper>.response-box {
  margin: auto !important;
}

.response-wrapper>.response-box>div {
  background-color: transparent !important;
  color: #FFF !important;
  margin-top: 10px;    
  margin-bottom: 10px !important;
}

.response-wrapper>.response-box>div:not(.response-buttons) {
  margin: auto !important;
}

.response-wrapper>.response-box.success>div[role="alert"] {
  border-color: #FFF !important;
}

.response-wrapper>.response-box div>svg {
  color: #FFFFFF !important;
}

.msg-content-container .error-message {
  color: #b8b8b8 !important;
}

.response-box svg:not(.error-message svg) {
  font-size: 40px;
  margin-right: 15px;
  margin-left: 0;
}

.msg-content-container .response-box svg {
  color: #FFFFFF !important;
}

.response-box:hover {
  cursor: pointer;
}

div.response-buttons {
  width: 100% !important;
  max-width: 532px !important;
  margin-top: 10px !important;
  justify-content: flex-end !important;
}

.response-buttons>button {
  height: 30px;
  width: 50px !important;
  min-width: unset !important;
  text-align: center;
  padding: 0 8px !important;
}

.response-buttons>button>svg {
  font-size: 18px !important;
  margin: auto !important;
}

.loading-box {
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  height: 100%;
}

.form-wrapper>.loading-box,
form.user-data>.loading-box {
  background-color: rgba(177, 192, 214, 0.15);
}

form.user-data>.response-box,
.form-wrapper>.response-box {
  background-color: #FFFFFF;
}

form {
  width: 100%;
}

form>*,
form>div>*:not(button) {
  width: 100%;
}

form h3 {
  margin-bottom: 35px;
}

.form-btn-container {
  width: 100%;
  margin: 20px 0 10px 0;
  text-align: right;
  background-color: transparent;
  justify-content: flex-end;
}

.form-btn-container>div {
  width: max-content;
}

.confirm-question {
  margin: 0 10px 0 0;
  font-size: 18px;
  color: #cc0000;
  letter-spacing: 0.6px;
  font-family: 'Courier New', Courier, monospace;
}

.d-column>.confirm-question {
  margin-bottom: 15px;
  margin-right: 0;
  font-size: 20px;
}

.form-btn-container>div>button {
  margin-left: 10px;
}

.form-btn-container>button[type="text"]:disabled {
  color: #cc0000 !important;
  opacity: 1 !important;
}

/* #endregion */

/* #region Dropdown */
.dropdown-hidden,
.dropdown-visible {
  margin-top: -8px !important;
  width: auto;
  z-index: 1000;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  outline: 1px solid transparent;
  padding: 0 10px;
  border-radius: 3px !important;
  font-size: 0;
}

.dropdown-visible {
  max-height: 1000px;
  outline-color: #d2d2d2;
  padding-top: 10px !important;
}

.dropdown-info {
  width: -webkit-fill-available !important;
  background-color: rgba(27, 152, 229, 0.15);
  text-align: left;
  padding-bottom: 10px;
  margin: 30px 0 12px 0 !important;
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
}

.dropdown-info>b {
  font-size: 14px;
}

/* #endregion */

/* #region FormUserData */
form.user-data {
  width: 600px;
  max-width: 95%;
  margin: auto;
  color: #000000;
  border-radius: 3px;
}

form.user-data svg {
  font-size: 25px;
}

form.user-data>div {
  margin-bottom: 20px;
}

form.user-data .list-wrapper {
  width: calc(100% - 30px);
  padding: 10px 15px;
  border: 1px solid #CCC;
  margin: 25px 0 30px 0;
  border-radius: 5px;
}

form.user-data .list-wrapper>p {
  margin: 10px 0 20px 0 !important;
  font-size: 15px !important;
  color: rgb(90, 90, 90) !important;
  font-family: Arial, Helvetica, sans-serif;
}

.list-div {
  justify-content: space-between;
  margin-bottom: 8px;
  color: #3030df;
  width: 100%;
  height: 30px;
  font-size: 14px
}

.list-div svg {
  font-size: 20px !important;
}

button.MuiAutocomplete-clearIndicator {
  bottom: 2px !important;
}

/* #endregion */

/* #region Mobile disabled */
.mobile-disabled {
  height: 100vh !important;
  margin: 0;
}

.md-wrapper {
  align-self: center;
  background-color: #b41414;
  border-radius: 5px;
  height: 90%;
  justify-content: space-around;
  margin: auto;
  width: calc(100% - 30px);
}

.md-wrapper>svg {
  font-size: 60vw;
}

.md-content-text {
  font-size: 4.25vw;
  text-align: center;
  width: calc(95% - 10px);
  max-width: 320px;
  margin-bottom: 50px;
}

button.md-download-btn {
  width: calc(95% - 10px);
  max-width: 320px;
  background-color: #000000;
  border: 1.5px solid #FFFFFF;
  border-radius: 8px;
  padding: 15px 5px !important;
  color: #FFFFFF !important;
  justify-content: space-around;
}

.md-download-btn>img {
  height: 50px;
  margin: auto 10px;
}

.md-download-btn>span {
  height: max-content;
  width: max-content;
  margin: auto 0;
  justify-self: center;
}

.md-download-btn span:not(.d-column) {
  align-self: flex-start;
  text-align: left;
  padding: 0 5px;
  margin: -7px 0;
  height: max-content;
  letter-spacing: 1px;
}

.md-download-btn>span>span:first-child {
  font-size: 12px;
}

.store-name {
  font-size: 30px;
  text-transform: none !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* #endregion */


@media(max-width: 1081px) {
  .position-view {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .position-view>p {
    padding: 3px 0 !important;
  }

  .position-view>p,
  .position-view>p>span {
    font-size: 14px !important;
  }
}

@media(min-width: 486px) OR (min-height: 485px) {
  .mobile-menu {
    display: none !important;
    visibility: hidden;
    z-index: -1;
  }
}

@media(max-height: 428px) {
  .md-wrapper {
    flex-direction: row !important;
  }

  .md-wrapper>svg {
    font-size: 60vh;
    margin: auto;
  }

  .md-content {
    width: 50%;
    justify-content: space-around;
  }

  .md-content-text {
    font-size: 18px !important;
  }
}

@media(max-width: 485px) {
  header {
    height: 100px;
  }

  header .position-view {
    display: none;
  }

  .logo-holder {
    margin: auto 10px !important;
  }

  header .logo-holder>p:first-child {
    font-size: 22px !important;
  }

  header .logo-holder>p:last-child {
    font-size: 15px !important;
  }

  .logotype {
    width: 70px;
    margin: 0;
  }

  .btn-container>button {
    width: 40px;
    height: 40px;
    border: 2px solid #E51B24;
    border-radius: 7px;
    margin: auto 0 auto 10px;
  }

  .mobile-menu {
    display: block !important;
    visibility: visible;
    z-index: 1;
  }

  .container-inside {
    padding: 10px 12px !important;
    width: auto !important;
  }

  .container-inside:not(.mobile-disabled) {
    margin-top: 110px;
    height: calc(100vh - 110px);
  }

  .start-logotype {
    width: 110px;
  }

  .start-logo-text {
    font-size: 22px;
  }

  .login-button {
    height: 60px;
    margin-top: 50px !important;
    max-width: 250px;
  }

  .box-header-wrapper>h3 {
    font-size: 18px !important
  }

  .box-header-wrapper>p {
    font-size: 14px !important
  }

  .notifications-container {
    right: -275px;
  }

  .visible-notifications {
    right: 0;
  }

  .list-li-wrapper>li>div>span {
    font-size: 12px !important;
  }

  .form-wrapper {
    width: auto;
    padding: 10px;
  }

  .form-wrapper>h3 {
    margin: 8px 0;
    font-size: 16px !important;
  }

  .form-btn-container {
    margin: 10px 0;
  }

  li:empty {
    display: none !important;
  }

  .notification-view>div>div>div {
    font-size: 14px !important;
  }

  .notification-btn {
    width: 40px !important;
    height: 40px !important;
  }

  .notification-btn svg {
    font-size: 20px !important;
  }

  .d-screen {
    flex-direction: column;
  }

  .side-menu>p {
    font-size: 14px !important;
  }

  .side-menu>p:hover {
    letter-spacing: 0 !important;
  }

  button.msg-text {
    padding: 10px 8px !important;
    font-size: 12px !important;
  }
}

@media(max-width: 385px) {
  header .logo-holder>p:first-child {
    font-size: 18px !important;
  }

  header .logo-holder>p:last-child {
    font-size: 12px !important;
  }
}